exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-calendar-js": () => import("./../../../src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-life-js": () => import("./../../../src/pages/life.js" /* webpackChunkName: "component---src-pages-life-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-outreach-js": () => import("./../../../src/pages/outreach.js" /* webpackChunkName: "component---src-pages-outreach-js" */),
  "component---src-pages-stephenministry-js": () => import("./../../../src/pages/stephenministry.js" /* webpackChunkName: "component---src-pages-stephenministry-js" */),
  "component---src-templates-sermon-template-js": () => import("./../../../src/templates/sermon-template.js" /* webpackChunkName: "component---src-templates-sermon-template-js" */),
  "component---src-templates-sermons-template-js": () => import("./../../../src/templates/sermons-template.js" /* webpackChunkName: "component---src-templates-sermons-template-js" */)
}

